import {Theme, ThemeMode} from './types';
import {createTheme} from '@mui/material/styles';
import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {ThemeProvider as EmotionThemeProvider} from '@emotion/react';

const emptyFn = () => {
	return;
};

const initialState = {
	isDarkModeOn: false,
	theme: ThemeMode.Light,
	toggleDarkMode: emptyFn,
};

const ThemeContext = createContext(initialState);

export function ThemeProvider({ children }: {children: ReactNode}) {
	const [dark, setDark] = useState(false); // Default theme is light

	// On mount, read the preferred theme from the persistence
	useEffect(() => {
		const isDark = localStorage.getItem('dark') === 'true';
		setDark(isDark);
	}, [dark]);

	// To toggle between dark and light modes
	const toggle = () => {
		const isDark = !dark;
		localStorage.setItem('dark', JSON.stringify(isDark));
		setDark(isDark);
	};

	const theme = dark ? ThemeMode.Dark : ThemeMode.Light;

	return (
		<ThemeContext.Provider value={{ theme, toggleDarkMode: toggle, isDarkModeOn: dark }}>
			<EmotionThemeProvider theme={themes[theme]}>
				{children}
			</EmotionThemeProvider>
		</ThemeContext.Provider>
	);
}

export const useThemeContext = () => useContext(ThemeContext);

const lightTheme: Theme = createTheme({
	palette: {
		mode: ThemeMode.Light,
		primary: {
			main: '#3A3042', // dark purple
		},
		background: {
			default: '#f5f5f5',
			paper: '#fff'
		},
	},
});

const darkTheme: Theme = createTheme({
	palette: {
		mode: ThemeMode.Dark,
		primary: {
			main: '#fc836c',
		},
		background: {
			default: '#191919'
		}
	},
	components: {
		MuiPaper: {
			styleOverrides: {
				root: {
					// this is to mock the behaviour of elevation:1 on modals to avoid background getting entirely colorless
					boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);',
					backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.051), rgba(255, 255, 255, 0.051));'
				}
			}
		},
	},
});

export const themes: Record<ThemeMode, Theme> = {
	[ThemeMode.Light]: lightTheme,
	[ThemeMode.Dark]: darkTheme,
};
