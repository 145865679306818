import React, {useEffect, useMemo} from 'react';
import {useAppSelector} from '../hooks';
import {
	selectEntryRepository,
	selectPopulatedRepositoryEntry,
	selectRepositoryEntryPropertyDefinitions,
	selectRepositoryEntrySelectOptions,
} from './repositoryEntryViewSlice';
import ConfirmDeleteEntryDialog, {useConfirmDeleteEntryDialog} from '../components/ConfirmDeleteEntryDialog';
import {PropertyDefinitionBelongsTo, PropertyDefinitionHas, RepositoryEntry} from '../types';
import {
	repositoryEntryLoaded,
	repositoryEntryUpdateLocalAction,
	selectIsLocationEntryLoaded,
	selectLocationEntryLocal,
	selectLocationId,
} from './contentSlice';
import {selectIsRepositoryEntryLoading} from './repositoryViewSlice';
import useAuth from '../components/Auth';
import useNavigateToLocationId from '../components/NavigateToLocationId';
import {useDispatch} from 'react-redux';
import {useLoadRepositoryEntryMutation, useRepositoryEntryUpdateMutation} from '../services/repositoryEntries';
import {Container} from '@mui/material';
import {useContentNavigationEffects} from '../components/ContentNavigationEffects';
import {useRepositoryEntryKeyboardEffects} from '../components/KeyboardEffects';
import AddRepositoryEntryForm, {
	AddRepositoryEntryFormDialog,
	useAddRepositoryEntryForm
} from '../components/AddRepositoryEntryForm';
import {useAddEntryForm} from '../components/AddEntryForm';
import {selectAddFormRepository} from './formDataSlice';
import EntryProperties from '../components/EntryProperties';
import SelectEntriesDrawer from '../components/SelectEntriesDrawer';
import {Helmet} from 'react-helmet';
import config from '../common/config';
import { useContentWrapper } from './ContentWrapper';

function RepositoryEntryView() {
	const {isAuthenticated, isAuthenticating} = useAuth();
	const navigateToLocationId = useNavigateToLocationId();
	const dispatch = useDispatch();
	const [loadRepositoryEntryMutation, {error: loadRepositoryEntryError}] = useLoadRepositoryEntryMutation();
	const isRepositoryEntryLoading = useAppSelector(selectIsRepositoryEntryLoading);
	const locationEntryLocal = useAppSelector(selectLocationEntryLocal);
	const locationId = useAppSelector(selectLocationId);
	const propertyDefinitions = useAppSelector(selectRepositoryEntryPropertyDefinitions);
	const populatedRepositoryEntry = useAppSelector(selectPopulatedRepositoryEntry);
	const repository = useAppSelector(selectEntryRepository);
	const addFormRepository = useAppSelector(selectAddFormRepository);
	const repositoryEntrySelectOptions = useAppSelector(selectRepositoryEntrySelectOptions);
	const [repositoryEntryUpdateMutation] = useRepositoryEntryUpdateMutation();
	const {isContentLoaded} = useContentWrapper();

	useContentNavigationEffects();
	useRepositoryEntryKeyboardEffects();

	const {isConfirmDeleteDialogOpen, confirmDeleteDialogEntry, closeConfirmDeleteDialog, submitConfirmDeleteDialog} = useConfirmDeleteEntryDialog();
	const {isAddDialogOpen, closeAddDialog} = useAddEntryForm();
	const {handleAddDialogSubmit, handleAddDialogValueChange} = useAddRepositoryEntryForm();
	const {openAddDialog} = useAddEntryForm();
	const [selectEntriesPropertyDef, setSelectEntriesPropertyDef] = React.useState<PropertyDefinitionBelongsTo | PropertyDefinitionHas | null>(null);

	const selectEntriesRows = useMemo(() => {
		if (!selectEntriesPropertyDef) {
			return [];
		}
		return (repositoryEntrySelectOptions as any)[selectEntriesPropertyDef.slug].map(({ value, label }: { value: string, label: string}) => ({ id: value, label: label }));
	}, [repositoryEntrySelectOptions, selectEntriesPropertyDef]);

	const selectEntriesPropertyValues = useMemo(() => {
		if (!populatedRepositoryEntry || !selectEntriesPropertyDef) {
			return [];
		}
		return populatedRepositoryEntry.data[selectEntriesPropertyDef.slug] as {value: string}[];
	}, [populatedRepositoryEntry, selectEntriesPropertyDef]);

	const handleAddEntryClick = (def: PropertyDefinitionBelongsTo | PropertyDefinitionHas) => {
		openAddDialog({ repositoryId: def.targetRepositoryId, linkToProperty: def, linkToEntryId: populatedRepositoryEntry!.id });
		setSelectEntriesPropertyDef(null);
	};

	const openSelectEntriesDrawer = (def: PropertyDefinitionBelongsTo | PropertyDefinitionHas) => {
		setSelectEntriesPropertyDef(def);
	};

	const closeSelectEntriesDrawer = () => {
		setSelectEntriesPropertyDef(null);
	};

	const submitSelectEntriesDrawer = (values: any[]) => {
		if (!selectEntriesPropertyDef) {
			return;
		}
		const payload = {
			id: locationId,
			values: {
				[selectEntriesPropertyDef.slug]: Array.from(values)
			}
		};
		if (isAuthenticated) {
			repositoryEntryUpdateMutation(payload);
		} else {
			dispatch(repositoryEntryUpdateLocalAction(payload));
		}
		setSelectEntriesPropertyDef(null);
	};

	/**
	 * while authenticated
	 */
	useEffect(() => {
		if (!isAuthenticated || isAuthenticating) {
			return;
		}
		if (!populatedRepositoryEntry && !isRepositoryEntryLoading) {
			loadRepositoryEntryMutation(locationId as string);
		}
	}, [isAuthenticated, isAuthenticating, populatedRepositoryEntry, isRepositoryEntryLoading, locationId]);

	/**
	 * while not authenticated, mock data load
	 */
	useEffect(() => {
		if (isAuthenticated || isAuthenticating) {
			return;
		}
		if (!populatedRepositoryEntry && locationEntryLocal) {
			dispatch(repositoryEntryLoaded(locationEntryLocal as RepositoryEntry));
		}
	}, [isAuthenticating, isAuthenticated, populatedRepositoryEntry, locationEntryLocal]);

	/**
	 * on failing to load repository info, navigate to root location
	 */
	useEffect(() => {
		if (loadRepositoryEntryError) {
			navigateToLocationId(null);
		}
	}, [loadRepositoryEntryError]);

	return isContentLoaded ? <>
		{repository && <Helmet>
			<title>{repository.title} - {config.appName}</title>
		</Helmet>}
		<Container maxWidth='md' sx={{mb:8}}>
			{populatedRepositoryEntry && <EntryProperties
				propertyDefinitions={propertyDefinitions}
				entryData={populatedRepositoryEntry!.data}
				onAddEntryClick={(def) => openSelectEntriesDrawer(def)}
			/>}
		</Container>
		<AddRepositoryEntryFormDialog
			open={isAddDialogOpen}
			onClose={closeAddDialog}
			onSubmit={handleAddDialogSubmit}
			title={addFormRepository ? `New ${addFormRepository.singular}` : ''}
		>
			<AddRepositoryEntryForm
				onChange={handleAddDialogValueChange}
			/>
		</AddRepositoryEntryFormDialog>
		<ConfirmDeleteEntryDialog
			open={isConfirmDeleteDialogOpen}
			entry={confirmDeleteDialogEntry}
			handleClose={closeConfirmDeleteDialog}
			handleSubmit={submitConfirmDeleteDialog}
			title=''
		/>
		<SelectEntriesDrawer
			def={selectEntriesPropertyDef}
			rows={selectEntriesRows}
			values={selectEntriesPropertyValues}
			onClose={closeSelectEntriesDrawer}
			onSubmit={submitSelectEntriesDrawer}
			onAddBtnClick={handleAddEntryClick}
		/>
	</> : null;
}

export default RepositoryEntryView;
