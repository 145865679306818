import React, {ReactNode} from 'react';
import {
	TableBody,
	TableContainer,
	TableRow,
	TableCell,
	Table,
	Box, IconButton,
	Container
} from '@mui/material';
import {Link} from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EntryMenu, { RowMenuItem, RowMenuProps } from './EntryMenu';

type EntriesListItemMenuProps = Omit<RowMenuProps,'onRowReorderClick' | 'onMenuBtnClick'>

interface EntriesListItemProps {
	id: string;
	label: string;
	onRowClick?: () => void;
	setMenuAnchorEl?: any;
	onMenuBtnClick?: () => void;
	selectedEntryId?: string | null;
	onEntryFocus?: (id: string) => void;
	onEntryBlur?: () => void;
	disabled?: boolean;
}

interface Props {
	children: ReactNode;
	rowMenuProps?: EntriesListItemMenuProps;
}

export const EntriesListItem = ({ id, label, onRowClick, setMenuAnchorEl, onMenuBtnClick, selectedEntryId, onEntryFocus, onEntryBlur, disabled }: EntriesListItemProps) => {
	const handleClick = (e: React.MouseEvent) => {
		e.preventDefault();
		if (onRowClick) {
			onRowClick();
		}
	};

	return <TableRow key={id} sx={{ '&:last-child td, &:last-child th': { border: 0 }, userSelect: 'none'}}>
		<TableCell sx={{p: (theme) => theme.spacing(1,2)}}>
			<Box display='flex' alignItems='center' sx={disabled ? {color: 'action.disabled'} : {minHeight: '40px'}}>
				{onRowClick ? <Link
					to={`/${id}`}
					onClick={handleClick}
					onFocus={onEntryFocus && (() => onEntryFocus(id))}
					onBlur={onEntryBlur && (() => onEntryBlur())}>
					{label}
				</Link> : <Box flex={1}>
					{label}
				</Box>}
				{setMenuAnchorEl && selectedEntryId === id && (
					<IconButton ref={setMenuAnchorEl} aria-label="menu" color="inherit" size="medium" onClick={onMenuBtnClick} style={{marginLeft:'0.5rem'}}>
						<MoreVertIcon />
					</IconButton>
				)}
			</Box>
		</TableCell>
	</TableRow>;
};

const EntriesList: React.FC<Props> = ({children, rowMenuProps}) => {
	return <>
		<TableContainer>
			<Table>
				<TableBody>
					{children}
				</TableBody>
			</Table>
		</TableContainer>
		{rowMenuProps ? <EntryMenu 
			options={rowMenuProps.options}
			anchorEl={rowMenuProps.anchorEl}
			onClose={rowMenuProps.onClose}
			item={rowMenuProps.item}
		/> : null}
	</>;
};

export default EntriesList;